// 首页-日历
import {
  USER
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 日历查询
export async function getMatter(params) {
  return request(`${USER}/homePage/getMatter`, METHOD.GET, params)
}

// 人事今日事件查询
export async function todayEvents(params) {
  return request(`${USER}/homePage/todayEvents`, METHOD.GET, params)
}

// 【日程】查询备忘录
export async function getSchedule(params) {
  return request(`${USER}/homePage/getSchedule`, METHOD.GET, params)
}

// 【日程】创建备忘录
export async function createMemo(params) {
  return request(`${USER}/homePage/createMemo`, METHOD.POST, params)
}

// 【日程】备忘录修改当前天
export async function updateCurrMemo(params) {
  return request(`${USER}/homePage/updateCurrMemo`, METHOD.POST, params)
}

// 【日程】备忘录修改当天及以后
export async function updateFutureMemo(params) {
  return request(`${USER}/homePage/updateFutureMemo`, METHOD.POST, params)
}

// 【日程】删除当天日程
export async function deleteCurrMemo(params) {
  return request(`${USER}/homePage/deleteCurrMemo`, METHOD.POST, params)
}

// 【日程】删除今日及以后
export async function deleteFutureMemo(params) {
  return request(`${USER}/homePage/deleteFutureMemo`, METHOD.POST, params)
}

// 【日程】删除当前循环的所有日程
export async function deleteAllMemo(params) {
  return request(`${USER}/homePage/deleteAllMemo`, METHOD.POST, params)
}
